/// <reference path="../../../node_modules/open-easyrtc/typescript_support/d.ts.files/client/easyrtc.d.ts" />

import { ElementRef, Injectable, NgZone } from "@angular/core";
import { Observable, Subject, BehaviorSubject } from "rxjs";
import { PopupService } from "./popup.service";
import { UserService } from "./user.service";
import { SettingsService } from "./settings.service";
import { environment } from "../../environments/environment";
import { TranslateService } from "@ngx-translate/core";

declare const easyrtc: Easyrtc & any;
declare const easyrtc_ft: any;

// Enable or disable EasyRTC Debug logs in Browser console
easyrtc.enableDebug(false);

@Injectable()
export class VideoStreamingService {
  private fileSender: any = null;

  // To observe the changes of connected users list
  private clientsSubject = new Subject<string>();

  // To set up the own id in connection
  private myIdSubject = new Subject<string>();
  private partnerIdSubject = new Subject<string>();
  private thirdConnectionSubject = new Subject<any>();

  // To observe the changes of receiving files
  private receivedFiles = new Subject<any>();

  // To observe the changes of sending files
  private sentFiles = new Subject<any>();
  private messagesSubject = new Subject<any>();
  private screenSharingSubject = new Subject<any>();
  private screenStream: any;
  private activeArea$ = new Subject<string>();

  public roomId: string;
  public partnerEasyrtcId = "";
  public connectedClientsList: Array<string> = [];
  public isConnected = new Subject<boolean>();
  public audioMuteEnabled = false;
  public videoMuteEnabled = false;
  public sketchIsActive = new BehaviorSubject<boolean>(false);
  public leftChat = false;

  public videoSelf: ElementRef<HTMLVideoElement>;
  public videoCaller: ElementRef<HTMLVideoElement>;

  constructor(
    private _ngZone: NgZone,
    private popupService: PopupService,
    public userService: UserService,
    public settingsService: SettingsService,
    public translate: TranslateService
  ) {
    easyrtc.setVideoObjectSrc = (element, stream) => {
      setTimeout(() => {
        if (stream) {
          element.srcObject = stream;
        }
      }, 1000);
    };

    this.userService.observeLogout.subscribe((loggedOut: boolean) => {
      console.log("Logging out ", loggedOut);
      this.hangup();
    });
  }

  settingsChangesStream(stream: MediaStream) {
    console.log("settingsChangesStream");
    easyrtc.closeLocalMediaStream();
    easyrtc.register3rdPartyLocalMediaStream(stream, "default");
    easyrtc.addStreamToCall(easyrtc.getIthCaller(0), "default", () => {});
    easyrtc.setVideoObjectSrc(this.videoSelf.nativeElement, stream);
  }

  get openArea$(): Observable<string> {
    return this.activeArea$.asObservable();
  }

  set activeArea(value: string) {
    this.activeArea$.next(value);
  }

  deleteScreenStream() {
    if (this.screenStream) {
      this.screenStream.getTracks().forEach((track) => {
        track.stop();
        this.screenStream.removeTrack(track);
      });
    }
    this.screenStream = null;
  }

  getConnectedClientsList(): Observable<string> {
    return this.clientsSubject.asObservable();
  }

  getMyId(): Observable<string> {
    return this.myIdSubject.asObservable();
  }

  getPartnerId(): string {
    return easyrtc.getIthCaller(0);
  }

  observePartnerId(): Observable<string> {
    return this.partnerIdSubject.asObservable();
  }

  getMessages(): Observable<any> {
    return this.messagesSubject.asObservable();
  }

  getConnectionStatus(): Observable<boolean> {
    return this.isConnected.asObservable();
  }

  getReceivedFilesStatus(): Observable<any> {
    return this.receivedFiles.asObservable();
  }

  getSentFilesStatus(): Observable<any> {
    return this.sentFiles.asObservable();
  }

  observeSketchState(): Observable<boolean> {
    return this.sketchIsActive.asObservable();
  }

  getScreenSharingStatus(): Observable<boolean> {
    return this.screenSharingSubject.asObservable();
  }

  observeThirdConnection(): Observable<any> {
    return this.thirdConnectionSubject.asObservable();
  }

  connect(isAllowedVideo: boolean, isAllowedAudio: boolean) {
    console.log("connect");
    this.roomId = this.userService.getRoomId();

    /*
    const response = await fetch(`https://webprax-f2f.de/api/turn-credentials`);
    const data = await response.json();
    const stunTurnServers = data.iceServers;
    */

    const stunTurnServers = {
      iceServers: [
        { urls: "stun:coturn.webprax.de:443" },
        {
          urls: "turn:coturn.webprax.de:3478",
          username: "webprax",
          credential: "webpraxfd8s0gxy9U59",
        },
        {
          urls: "turn:coturn.webprax.de:443?transport=tcp",
          username: "webprax",
          credential: "webpraxfd8s0gxy9U59",
        },
      ],
    };

    easyrtc.setIceUsedInCalls(stunTurnServers);
    easyrtc.enableVideo(isAllowedVideo);
    easyrtc.enableAudio(isAllowedAudio);
    easyrtc.enableDataChannels(true);
    easyrtc.setPeerListener(this.addMessageToChat.bind(this));
    easyrtc.setSocketUrl(environment.socketUrl);
    easyrtc.setAcceptChecker((easyrtcId, responseFunction) => {
      responseFunction(true);
    });

    const convertListToButtonShim = (
      roomName: string,
      data: any,
      isPrimary: boolean
    ): void => {
      this.convertListToButtons(roomName, data, isPrimary);
    };

    if (!isAllowedAudio && !isAllowedVideo) {
      const canvasElement: any = document.createElement("canvas");
      const ctx = canvasElement.getContext("2d");

      ctx.beginPath(); // Начинает новый путь
      ctx.moveTo(0, 0); // Передвигает перо в точку (30, 50)
      ctx.lineTo(0, 1); // Рисует линию до точки (150, 100)
      ctx.stroke(); // О
      document.body.appendChild(canvasElement);
      easyrtc.enableVideo(true);
      const stream: MediaStream = canvasElement.captureStream(25);
      easyrtc.register3rdPartyLocalMediaStream(stream, "default");
    }
    easyrtc.setRoomOccupantListener(convertListToButtonShim);

    easyrtc.easyApp(
      this.roomId,
      "videoSelf",
      ["videoCaller"],
      this.loginSuccess,
      this.loginFailure
    );

    this.messagesSubject.subscribe((messageData) => {
      console.log(messageData);
      if (messageData.sender !== "me" && messageData.message.error) {
        this._ngZone.run(() => {
          this.popupService.openErrorNotification(
            this.translate.instant(messageData.message.error)
          );
        });
      }
    });
  }

  performCall(clientEasyrtcId: string) {
    console.log("performCall", clientEasyrtcId);
    if (easyrtc.getConnectStatus(clientEasyrtcId) === easyrtc.NOT_CONNECTED) {
      console.log(easyrtc.NOT_CONNECTED);
      try {
        easyrtc.call(
          clientEasyrtcId,
          (caller, media) => {
            // success callback
            console.log("easyrtc.call success", clientEasyrtcId, media);
            if (media === "datachannel") {
              this.connectedClientsList[clientEasyrtcId] = true;
              this.showConnectionType(clientEasyrtcId);
            }
          },
          (errorCode, errorText) => {
            this.connectedClientsList[clientEasyrtcId] = false;
            console.log("failed ", errorCode, errorText);
          },
          (wasAccepted) => {
            console.log("was accepted=" + wasAccepted);
          }
        );
      } catch (callerror) {
        console.log("callerror", callerror);
      }
    }
  }

  toggleAudio(audioEnabled: boolean): true | void {
    try {
      easyrtc.enableMicrophone(audioEnabled);
    } catch (error) {
      this.popupService.openErrorNotification(error);
    }

    if (this.screenStream) {
      this.screenStream.getAudioTracks()[0].enabled = audioEnabled;
    }

    return true;
  }

  toggleVideo(videoEnabled: boolean): true | void {
    try {
      easyrtc.enableCamera(videoEnabled);
      this.videoMuteEnabled = !videoEnabled;
      return true;
    } catch (error) {
      this.popupService.openErrorNotification(error);
    }
  }

  changeCamera(video) {
    console.log("changeCamera", video);
    this.settingsService.isDeviceChanged = false;
    const videoSelected = this.settingsService.videoSelect
      ? this.settingsService.videoSelect.deviceId
      : sessionStorage.camera;

    if (!videoSelected) {
      this.settingsService.setDefaultVideoDevice();
    }

    easyrtc.setVideoSource(videoSelected); // Set the id of back camera. Must be called before easyrtc.initMediaSource()
    easyrtc.initMediaSource(() => {
      easyrtc.setVideoObjectSrc(video.nativeElement, easyrtc.getLocalStream());
      easyrtc.easyApp(
        this.roomId,
        "videoSelf",
        ["videoCaller"],
        this.loginSuccess.bind(this),
        this.loginFailure.bind(this)
      );
    });
  }

  screenShare() {
    try {
      (navigator.mediaDevices as any)
        .getDisplayMedia({
          video: true,
          audio: {
            echoCancellation: true, // Optional: Improves audio quality
            noiseSuppression: true, // Optional: Improves audio quality
            sampleRate: 44100, // Optional: Set audio sample rate
          },
        })
        .then((desktopStream) => {
          const videoTracks = desktopStream.getVideoTracks();
          const screenAudioTracks = desktopStream.getAudioTracks(); // Get screen/tab audio tracks

          const microphoneAudioTracks = easyrtc
            .getLocalStream()
            .getAudioTracks();

          const combinedAudioTracks = [
            ...screenAudioTracks,
            ...microphoneAudioTracks,
          ];

          // Combine video and audio tracks to create a screen stream
          const screenStream = easyrtc.buildLocalMediaStream(
            "screen",
            videoTracks,
            combinedAudioTracks
          );

          // Enable or disable microphone audio based on user's mute settings
          combinedAudioTracks.forEach((track) => {
            track.enabled = !this.audioMuteEnabled;
          });

          this.screenStream = screenStream;
          const videoSelf = document.getElementById("videoSelf");
          const clientId = this.partnerEasyrtcId;

          easyrtc.setVideoObjectSrc(videoSelf, screenStream);

          console.log(`Adding screen stream to call with ${clientId}`);
          easyrtc.addStreamToCall(clientId, "screen", () => {});

          this.screenSharingSubject.next(true);

          this.addStreamStopListener(screenStream, () => {
            this.closeScreenSharingStream(screenStream);
          });

          desktopStream.getTracks().forEach((track) => {
            track.stop();
            desktopStream.removeTrack(track);
          });
        })
        .catch((errorCode) => {
          console.error("Problem getting desktop stream", errorCode);
          if (this.isSafari()) {
            // show error for Safari only because it's dialog is in form "Allow domain to use your camera and microphone?"
            // with [Allow] and [Deny] buttons
            // other browsers shows [Cancel] and [Share] buttons, thus we don't need this popup for them
            this.popupService.openErrorNotification(
              this.translate.instant("popups.promptPermission")
            );
          }
        });

      easyrtc.initMediaSource(
        (mediaStream) => {
          const videoCaller = document.getElementById("videoCaller");
          easyrtc.setVideoSource(videoCaller, mediaStream);
        },
        (errorCode, errorText) => {
          console.log("Error in initMediaSource", errorCode, errorText);
        },
        undefined
      );
    } catch (error) {
      this.popupService.openErrorNotification(
        this.translate.instant("popups.screenSharingError")
      );
    }
  }

  stopScreenShare() {
    try {
      this.closeScreenSharingStream(this.screenStream);
    } catch (error) {
      console.log("Stop screenshare button error");
    }
  }

  buildDragNDropRegion(dropArea: any) {
    easyrtc_ft.buildDragNDropRegion(dropArea);
  }

  fileHandler(files) {
    const clientId = easyrtc.getIthCaller(0);

    if (clientId) {
      if (easyrtc.getConnectStatus(clientId) === easyrtc.IS_CONNECTED) {
        if (!this.fileSender) {
          this.fileSender = easyrtc_ft.buildFileSender(
            clientId,
            this.updateStatus
          );
        }
        this.fileSender(files, true);
      }
    } else {
      this.popupService.openErrorNotification(
        this.translate.instant("popups.fileSendingError")
      );
    }
  }

  blobAcceptor(otherGuy, blob, filename) {
    // Create a URL for the blob
    const blobUrl = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.setAttribute("href", blobUrl);
    a.setAttribute("target", "_blank");
    a.click();

    // Old Version
    // easyrtc_ft.saveAs(blob, filename);
  }

  sendStuffP2P(messageData) {
    if (messageData.text) {
      if (messageData.text.replace(/\s/g, "").length === 0) {
        // Don't send just whitespace
        return;
      }
    }
    if (
      easyrtc.getConnectStatus(this.partnerEasyrtcId) === easyrtc.IS_CONNECTED
    ) {
      easyrtc.sendPeerMessage(this.partnerEasyrtcId, "message", messageData);
      this.addMessageToChat("me", "msg", messageData);
    } else {
      this.popupService.openErrorNotification(
        this.translate.instant("popups.chatError")
      );
    }
  }

  updateMessages(messages) {
    easyrtc.sendPeerMessage(this.partnerEasyrtcId, "chat", { chat: messages });
  }

  isSafari(): boolean {
    return (
      /constructor/i.test(window["HTMLElement"]) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof window["safari"] !== "undefined" &&
            window["safari"].pushNotification)
      )
    );
  }

  async checkPermissions(): Promise<boolean> {
    const iosPlatforms = ["iPhone", "iPad", "iPod"];
    const isIOS = iosPlatforms.indexOf(window.navigator.platform) !== -1;

    const videoDevice = await navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        return devices.find((device) => device.kind === "videoinput");
      });

    /**
     * Skip permissions check on iOS, because it causes camera to turn black
     * https://bugs.webkit.org/show_bug.cgi?id=179363
     */
    if (isIOS) {
      console.log(
        "Skipped check media permissions for iOS and Safari Desktop."
      );
      return Promise.resolve(true);
    }
    const cameraId = sessionStorage.camera || null;
    const microphoneId = sessionStorage.microphone || null;
    return new Promise<boolean>((resolve, reject) => {
      if (
        navigator.userAgent.includes("Chrome") &&
        navigator["permissions"] &&
        typeof navigator["permissions"].query === "function"
      ) {
        Promise.all([
          navigator["permissions"].query({
            name: "camera",
            deviceId: cameraId,
          }),
          navigator["permissions"].query({
            name: "microphone",
            deviceId: microphoneId,
          }),
        ]).then(([{ state: videoState }, { state: audioState }]) => {
          if (videoState === "denied" || audioState === "denied") {
            this.popupService.openErrorNotification(
              this.translate.instant("popups.deniedPermission")
            );
            resolve(false);
          }
          if (videoState === "granted" || audioState === "granted") {
            resolve(true);
          }
          resolve(false);
        });
      } else {
        const constraints = {
          ...(videoDevice && {
            video: { deviceId: cameraId },
          }),
          audio: { deviceId: microphoneId },
        };
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(() => true)
          .catch((error) => {
            if (error.name === "NotAllowedError") {
              this.popupService.openErrorNotification(
                this.translate.instant("popups.deniedPermissionFF")
              );
            }
            return false;
          })
          .then((res) => resolve(res));
      }
    });
  }

  disconnect() {
    easyrtc.disconnect();
    easyrtc.enableMicrophone(false);
    easyrtc.enableCamera(false);
    this.clearConnectList();
    easyrtc.closeLocalMediaStream();
  }

  private loginSuccess = (easyrtcId) => {
    console.log("login success");
    easyrtc_ft.buildFileReceiver(
      this.acceptRejectCB,
      this.blobAcceptor,
      this.receiveStatusCB
    );
    this.updateMyEasyRTCId(easyrtcId);
  };

  private loginFailure = (error) => {
    console.error("easyrtc.easyApp loginFailure", error);
    if (error === "MEDIA_ERR") {
      if (
        this.settingsService.videoDevices.filter(
          (device) =>
            device.deviceId !== this.settingsService.videoSelect.deviceId
        ).length > 0
      ) {
        console.log("clear");
        sessionStorage.clear();
        this.settingsService.setDefaultVideoDevice();
        this.settingsService.setDefaultAudioInputDevice();
        this.changeCamera(this.videoSelf);
      } else {
        easyrtc.setStreamAcceptor((callerEasyrtcid, stream) => {
          easyrtc.setVideoObjectSrc(this.videoCaller.nativeElement, stream);
        });

        easyrtc.connect(
          this.roomId,
          (easyrtcid: string, roomOwner: boolean) => {
            easyrtc_ft.buildFileReceiver(
              this.acceptRejectCB,
              this.blobAcceptor,
              this.receiveStatusCB
            );
            this.updateMyEasyRTCId(easyrtcid);
          },
          () =>
            this._ngZone.run(() => {
              this.popupService.openErrorNotification(
                this.translate.instant("popups.deviceError2")
              );
            })
        );
      }
    }
  };

  private convertListToButtons(
    roomName: string,
    occupants: Easyrtc_PerRoomData,
    isPrimary: boolean
  ): void {
    if (Object.keys(occupants).length < 2) {
      this.clearConnectList();
      for (const easyrtcId of Object.keys(occupants)) {
        const client = easyrtc.idToName(easyrtcId);
        this.partnerEasyrtcId = easyrtcId;
        this.partnerIdSubject.next(easyrtcId);
        this.connectedClientsList.push(client);
        this.clientsSubject.next(client);
        this.isConnected.next(true);
        console.log("connected", true);
      }
    } else {
      this.thirdConnectionSubject.next(easyrtc.getConnectionCount());
    }
  }

  private addMessageToChat(who, msgType, content) {
    this.messagesSubject.next({ sender: who, message: content });
  }

  private clearConnectList() {
    this.connectedClientsList = [];
    this.clientsSubject.next();
    this.partnerIdSubject.next("");
    this.isConnected.next(false);
  }

  private hangup() {
    try {
      easyrtc.hangupAll();
      easyrtc.disconnect();
      this.isConnected.next(false);
      this.partnerIdSubject.next("");
    } catch (error) {
      this.popupService.openErrorNotification(error);
    }
  }

  private addStreamStopListener(stream, callback) {
    stream.addEventListener(
      "ended",
      function () {
        callback();
        callback = function () {};
      },
      false
    );
    stream.addEventListener(
      "inactive",
      function () {
        callback();
        callback = function () {};
      },
      false
    );
    stream.getTracks().forEach(function (track) {
      track.addEventListener(
        "ended",
        function () {
          callback();
          callback = function () {};
        },
        false
      );
      track.addEventListener(
        "inactive",
        function () {
          callback();
          callback = function () {};
        },
        false
      );
    });
  }

  private closeScreenSharingStream(streamToClose: any) {
    console.log(
      "closeScreenSharingStream streamToClose",
      streamToClose ? streamToClose.streamName : "",
      streamToClose
    );

    const videoSelf = this.videoSelf.nativeElement;
    const clientId = this.partnerEasyrtcId;
    const localStream = easyrtc.getLocalStream();
    easyrtc.register3rdPartyLocalMediaStream(localStream, "default");
    easyrtc.setVideoObjectSrc(videoSelf, localStream);
    easyrtc.addStreamToCall(clientId, "default", () => {});

    this.screenSharingSubject.next(false);
    this.deleteScreenStream();

    try {
      easyrtc.enableMicrophone(!this.audioMuteEnabled);
    } catch (error) {
      console.log("audio mute error ", error);
    }

    try {
      easyrtc.enableCamera(!this.videoMuteEnabled);
    } catch (error) {
      console.log("video mute error ", error);
    }
  }

  private updateStatus = (state) => {
    this._ngZone.run(() => {
      this.sentFiles.next(state);
    });
    return true;
  };

  private acceptRejectCB = async (otherGuy, files, wasAccepted) => {
    const fileName = files[0].name;
    const dialogMessage = `
        ${this.userService.getDoctorName()} ${this.translate.instant(
      "popups.sendFile"
    )} ${fileName}
        ${this.translate.instant(
          "popups.sendFileEnding"
        )}. ${this.translate.instant("popups.downloadFile")}`;

    this._ngZone.run(() =>
      this.popupService
        .openDialog(
          this.translate.instant("popups.acceptFile"),
          dialogMessage,
          this.translate.instant("popups.download"),
          this.translate.instant("popups.cancel")
        )
        .subscribe((result) => {
          console.log("result", result);
          wasAccepted(result);
        })
    );
  };

  private receiveStatusCB = (otherGuy, msg) => {
    this.receivedFiles.next(msg);
    return true;
  };

  private updateMyEasyRTCId(myEasyRTCId: string) {
    this.myIdSubject.next(myEasyRTCId);
  }

  private showConnectionType(clientEasyrtcId: string) {
    // Candidate types to check
    // https://developer.mozilla.org/en-US/docs/Web/API/WebRTC_API/Connectivity
    // FireFox
    try {
      const pc = easyrtc.getPeerConnectionByUserId(clientEasyrtcId);
      if (pc) {
        pc.getStats().then((stats: RTCStatsReport) => {
          // console.log('getPeerConnectionByUserId values', Array.from((stats as any).values()));
          const selectedCandidatePair = Array.from(
            (stats as any).values()
          ).find((candidate) => {
            return candidate["selected"] === true;
          });
          // console.log('selectedCandidatePair ', selectedCandidatePair);

          const selectedLocalCandidate = Array.from(
            (stats as any).values()
          ).find((candidate) => {
            return selectedCandidatePair
              ? candidate["id"] === selectedCandidatePair["localCandidateId"]
              : undefined;
          });
          // console.log('selectedLocalCandidate ', selectedLocalCandidate);

          const selectedRemoteCandidate = Array.from(
            (stats as any).values()
          ).find((candidate) => {
            return selectedCandidatePair
              ? candidate["id"] === selectedCandidatePair["remoteCandidateId"]
              : undefined;
          });
          // console.log('selectedRemoteCandidate ', selectedRemoteCandidate);

          // Check if at least one of the candidates in the pair is 'relay' type
          if (
            selectedLocalCandidate &&
            selectedRemoteCandidate &&
            (selectedLocalCandidate["candidateType"] === "relay" ||
              selectedRemoteCandidate["candidateType"] === "relay")
          ) {
            this._ngZone.run(() => {
              this.popupService.openErrorNotification(
                this.translate.instant("popups.turnMessage")
              );
              this.sendStuffP2P({ error: "popups.turnMessage" });
            });
          }
        });
      }

      // Chrome
      easyrtc.getPeerStatistics(
        clientEasyrtcId,
        (easyrtcId, data) => {
          // console.log('getPeerStatistics', easyrtcId, 'data', data);
          // console.log('values', data.values());

          // find selected candidate among channel
          // sample: Channel-0-1.selectedCandidatePairId: 'Conn-0-1-0"
          const selectedCandidateId = Object.values(data)
            .filter(
              (key) => data[key + ".selectedCandidatePairId"] !== undefined
            )
            .map((key) => data[key + ".selectedCandidatePairId"])[0]; // eq: "Conn-0-1-0"

          if (selectedCandidateId) {
            const remoteCandidateType =
              data[selectedCandidateId + ".googRemoteCandidateType"];
            console.log(
              "selectedCandidateId remote",
              selectedCandidateId,
              remoteCandidateType
            );
            const localCandidateType =
              data[selectedCandidateId + ".googLocalCandidateType"];
            console.log(
              "selectedCandidateId local",
              selectedCandidateId,
              localCandidateType
            );
            if (
              remoteCandidateType === "relay" ||
              localCandidateType === "relay"
            ) {
              this._ngZone.run(() => {
                this.popupService.openErrorNotification(
                  this.translate.instant("popups.turnMessage")
                );
                this.sendStuffP2P({ error: "popups.turnMessage" });
              });
            } else {
              console.log(
                "Connection type is ",
                remoteCandidateType,
                localCandidateType
              );
            }
          } else {
            console.log("Wasn't able to find selected candidate");
          }
        },
        undefined
      );
    } catch (e) {
      console.warn(`Problem in showConnectionType. Can be ignored.`, e);
    }
  }
}
